<script setup>
import { onMounted, reactive, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import UserTypeButton from "../components/buttons/UserTypeButton.vue";
import UtilityButton from "../components/buttons/UtilityButton.vue";
import ErrorCard from "../components/cards/ErrorCard.vue";
// import DriverIcon from "../components/icons/DriverIcon.vue";
import MapMarker from "../components/icons/MapMarker.vue";
// import UserIcon from "../components/icons/UserIcon.vue";
import PageFooter from "../components/layout/PageFooter.vue";
import LoadingBackdrop from "../components/utils/LoadingBackdrop.vue";
import { USER_TYPE } from "../const/auth";
import { BASE_PATH } from "../const/route";
import { useAuth } from "../services/firebase";
import { getUserCode, getUserType, validateUser } from "../services/utils/auth";
import { reportError } from "../services/utils/telemetry";
import { useLmsStore } from "../stores/lms";

const { signIn } = useAuth();
const route = useRoute();
const router = useRouter();
const store = useLmsStore();

const userCode = ref("");
const state = reactive({
    isLoading: false,
    errorMsg: "",
});

onMounted(async () => {
    if (validateUser(route.query.user)) {
        store.setUserType(getUserType(route.query.user));
        store.setUserCode(getUserCode(route.query.user));
        userCode.value = getUserCode(route.query.user);
    }
});

const onSubmit = async (ev) => {
    ev.preventDefault();
    const rawCode = userCode.value.toString().trim();
    if (rawCode === "") return;

    try {
        state.isLoading = true;
        store.setUserCode(rawCode);
        const code = `${store.userType.toString()}${rawCode}`;
        await signIn(code);

        router.replace({ name: BASE_PATH });
    } catch (error) {
        reportError(error);
        state.errorMsg = "An unexpected error occurred. Please try again.";
    } finally {
        state.isLoading = false;
    }
};
</script>

<template>
    <LoadingBackdrop v-if="state.isLoading" />
    <div class="h-screen flex flex-col items-center overflow-auto">
        <div
            class="my-auto md:m-auto rounded sm:w-1/2 w-full flex flex-col overflow-hidden pb-4"
        >
            <div class="text-center py-8 rounded-t w-full">
                <div class="pb-8">
                    <div class="logo w-60 h-16 mx-auto"></div>
                </div>

                <div v-if="state.errorMsg !== ''" class="m-8">
                    <ErrorCard>
                        {{ state.errorMsg }}
                    </ErrorCard>
                </div>
                <form>
                    <div class="my-8 grid grid-flow-col justify-center gap-4">
                        <UserTypeButton
                            label="Walker"
                            :user-type="USER_TYPE.WALKER"
                            :on-click="
                                () => store.setUserType(USER_TYPE.WALKER)
                            "
                            ><MapMarker :size="8"
                        /></UserTypeButton>
                        <!-- <UserTypeButton
                            label="Driver"
                            :user-type="USER_TYPE.DRIVER"
                            :on-click="
                                () => store.setUserType(USER_TYPE.DRIVER)
                            "
                            ><DriverIcon :size="8"
                        /></UserTypeButton>
                        <UserTypeButton
                            label="Supervisor"
                            :user-type="USER_TYPE.SUPERVISOR"
                            :on-click="
                                () => store.setUserType(USER_TYPE.SUPERVISOR)
                            "
                            ><UserIcon :size="8"
                        /></UserTypeButton> -->
                    </div>
                    <div class="my-8 mx-4 sm:mx-16">
                        <div>
                            <div class="flex rounded-lg shadow-sm">
                                <span
                                    class="px-4 inline-flex items-center min-w-fit rounded-s-md border border-e-0 border-gray-200 bg-gray-50 text-sm text-gray-500 dark:bg-gray-700 dark:border-gray-700 dark:text-gray-400"
                                    >{{ store.userType }}</span
                                >
                                <input
                                    type="number"
                                    v-model="userCode"
                                    placeholder="Enter your user code"
                                    :autofocus="true"
                                    :required="true"
                                    @keydown.enter="onSubmit"
                                    class="py-2 px-3 pe-11 block w-full border-gray-200 shadow-sm rounded-e-lg text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"
                                />
                            </div>
                        </div>
                    </div>
                    <UtilityButton
                        label="Continue"
                        type="submit"
                        :on-click="onSubmit"
                    />
                </form>
            </div>
        </div>
        <PageFooter />
    </div>
</template>

<style scoped>
.logo {
    background-image: url("../assets/reach-peppermint.png");
    background-size: 100% 100%;
}
</style>
