<script setup>
import { onMounted } from "vue";
import WalkerWelcome from "../components/sections/walkers/WalkerWelcome.vue";
import { USER_TYPE } from "../const/auth";
import { getInductionStatus } from "../services/apis/lms";
import { useLmsStore } from "../stores/lms";

const store = useLmsStore();

onMounted(() => {
    getInductionStatus();
});
</script>

<template>
    <WalkerWelcome v-if="USER_TYPE.WALKER === store.userType" />
</template>
