export const HTTP = {
    GET: "GET",
    POST: "POST",
    PUT: "PUT",
};

const ociGateway = import.meta.env.VITE_OCI_GATEWAY_URL;

export const ENDPOINT = {
    GET_USER_PROFILE: () => `${ociGateway}/personnel/profile`,
    UPDATE_INDUCTION_STATUS: () => `${ociGateway}/personnel/profile`,
};

export const HTTP_CODES = {
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    SERVER_ERROR: 500,
};
