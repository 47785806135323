<script setup>
import { useLmsStore } from "../../stores/lms";

const props = defineProps({
    label: { type: String, required: true },
    userType: { type: String, required: true },
    onClick: { type: Function, required: true },
});

const store = useLmsStore();
</script>

<template>
    <div
        class="card w-24 shadow-xl border-2 border-rounded hover:cursor-pointer"
        :class="{ 'border-peppermint': store.userType === props.userType }"
        :onclick="props.onClick"
    >
        <div class="card-body items-center text-center">
            <slot></slot>
            <p>{{ props.label }}</p>
        </div>
    </div>
</template>
