import { ENDPOINT, HTTP } from "../../const/http";
import { useLmsStore } from "../../stores/lms";
import { constructAuthOptions, useApi } from "./common";

export const getInductionStatus = async () => {
    const { data, fetchApi } = useApi({
        optionsHandler: constructAuthOptions,
    });

    await fetchApi({
        endPoint: ENDPOINT.GET_USER_PROFILE(),
        method: HTTP.GET,
    });

    if (data.value) {
        const hasInduction = data.value.profile?.hs_induction === "Y";
        const store = useLmsStore();

        store.setHasInduction(hasInduction);
    }
    return data.value;
};

export const updateInductionStatus = async () => {
    const { statusCode, error, fetchApi } = useApi({
        optionsHandler: constructAuthOptions,
    });

    await fetchApi({
        endPoint: ENDPOINT.UPDATE_INDUCTION_STATUS(),
        method: HTTP.PUT,
        payload: { hs_induction: "Y" },
    });

    if (statusCode.value !== 200 && error.value) throw error.value;

    const store = useLmsStore();
    store.setHasInduction(true);
};
