<script setup>
import { reactive } from "vue";
import ProgressSpin from "../utils/ProgressSpin.vue";
import UtilityButton from "./UtilityButton.vue";
import { updateInductionStatus } from "../../services/apis/lms";
import { useRouter } from "vue-router";
import { CONFIRMED_ACK_PATH } from "../../const/route";
import { showToast, toastStatus } from "../../services/utils/toast";
import { reportError } from "../../services/utils/telemetry";

const state = reactive({
    isLoading: false,
    isAck: false,
});

const router = useRouter();

const onSubmit = async (ev) => {
    ev.preventDefault();

    try {
        state.isLoading = true;
        await updateInductionStatus();
        router.replace({ name: CONFIRMED_ACK_PATH });
    } catch (e) {
        reportError(e);
        showToast(
            "An unexpected error has occurred. Please try again.",
            toastStatus.ERROR
        );
    } finally {
        state.isLoading = false;
    }
};
</script>

<template>
    <div class="flex justify-center mt-4 mb-8">
        <label class="cursor-pointer label">
            <input
                type="checkbox"
                v-model="state.isAck"
                class="checkbox checkbox-success"
            />
            <span class="ml-4 label-text"
                >I acknowledge I have read and understood the information
                provided.</span
            >
        </label>
    </div>
    <div class="text-center">
        <ProgressSpin v-if="state.isLoading" />
        <UtilityButton
            v-else
            label="Confirm"
            :on-click="onSubmit"
            :disabled="!state.isAck"
        />
    </div>
</template>
