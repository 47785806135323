import { useAuth } from "../services/firebase";
import { createRouter, createWebHistory } from "vue-router";
import { BASE_PATH, SIGN_IN_PATH } from "../const/route";
import { routes } from "./routes";

const { isUserSignedIn } = useAuth();

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes,
    strict: true,
});

router.beforeEach(async (to) => {
    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
    const signedIn = await isUserSignedIn();

    if (requiresAuth && !signedIn) {
        const loginPath = window.location.pathname;

        return {
            name: SIGN_IN_PATH,
            query: { from: loginPath, user: to.query?.user },
        };
    }
    if (to.name === SIGN_IN_PATH && signedIn && !to.query?.user) {
        return { name: BASE_PATH };
    }
});

export default router;
