<script setup>
import ButtonBase from "./ButtonBase.vue";

const props = defineProps({
    type: { type: String, required: false, default: "button" },
    label: { type: String, required: true },
    onClick: { type: Function, required: true },
    tooltip: { type: String },
    inverse: { type: Boolean },
    noBorder: { type: Boolean },
    disabled: { type: Boolean },
});
</script>

<template>
    <ButtonBase
        :type="props.type"
        :class="{
            'inverse-button': props.inverse,
            'peppermint-button': !props.inverse,
            'inverse-button-border': !props.noBorder,
        }"
        :label="props.label"
        :tooltip="props.tooltip"
        :on-click="props.onClick"
        :disabled="props.disabled"
    >
        <slot></slot>
    </ButtonBase>
</template>
