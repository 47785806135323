import { USER_TYPE } from "../../const/auth";

export const validateUser = (user) => {
    if (!user || user.toString().length < 4) return false;

    if (
        ![USER_TYPE.DRIVER, USER_TYPE.SUPERVISOR, USER_TYPE.WALKER].includes(
            getUserType(user)
        )
    ) {
        return false;
    }

    const userCode = getUserCode(user);

    return !isNaN(parseFloat(userCode)) && isFinite(userCode);
};

export const getUserType = (user) => {
    return user.toString().substring(0, 2).toUpperCase();
};

export const getUserCode = (user) => {
    return user.toString().substring(2).trim();
};

export const formatUserCode = (userType, userCode) => {
    return `${userType}${userCode}`;
};
