<script setup>
import ProgressSpin from "./ProgressSpin.vue";
const props = defineProps({
    text: { type: String, required: false, default: "" },
});
</script>
<template>
    <div
        class="h-screen absolute flex flex-col justify-center bg-bluesteel-tint-5 z-[1000] opacity-80 inset-0"
    >
        <ProgressSpin></ProgressSpin>
        <div class="mt-2 text-center">{{ props.text }}</div>
    </div>
</template>
