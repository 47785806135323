<script setup>
const props = defineProps({
    label: { type: String, required: true },
    showDivider: { type: Boolean, required: false, default: true },
});
</script>

<template>
    <div>
        <h3 class="font-semibold text-xl">{{ props.label }}</h3>
        <hr v-if="props.showDivider" class="mt-1" />
    </div>
</template>
