import { ref } from "vue";
import { defineStore } from "pinia";
import { USER_TYPE } from "../const/auth";

export const useLmsStore = defineStore("lms", () => {
    const userCode = ref("");
    const userType = ref(USER_TYPE.WALKER);
    const loading = ref(true);
    const error = ref(undefined);
    const statusCode = ref(undefined);
    const hasInduction = ref(false);

    function setHasInduction(status) {
        hasInduction.value = status;
    }

    function setLoading(isLoading) {
        loading.value = isLoading;
    }

    function setError(e) {
        error.value = e;
    }

    function setStatusCode(code) {
        statusCode.value = code;
    }

    function setUserCode(code) {
        userCode.value = code;
    }

    function setUserType(type) {
        userType.value = type;
    }

    return {
        hasInduction,
        error,
        loading,
        statusCode,
        userCode,
        userType,
        setHasInduction,
        setError,
        setStatusCode,
        setLoading,
        setUserCode,
        setUserType,
    };
});
