import * as Sentry from "@sentry/vue";

export const reportError = (error) => {
    Sentry.captureException(error);
};

export const setTelemetryUser = (user) => {
    if (user) {
        Sentry.setUser({
            id: user.id,
            username: user.name,
            email: user.email,
        });
    } else {
        Sentry.setUser(null);
    }
};
