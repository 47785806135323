import firebaseApp from "./init";
import { getAuth, signOut, signInWithCustomToken } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import { reactive } from "vue";
import { AUTH_UNKNOWN } from "../../const/auth";
import { SIGN_IN_PATH } from "../../const/route";
import { reportError, setTelemetryUser } from "../../services/utils/telemetry";

const auth = getAuth(firebaseApp);
const authState = reactive({
    auth,
    authenticated: AUTH_UNKNOWN,
    loading: true,
});
const functions = getFunctions(firebaseApp, "australia-southeast1");

const getCurrentUser = () => {
    return new Promise((resolve, reject) => {
        const unsub = auth.onAuthStateChanged((user) => {
            authState.loading = authState.loading
                ? !authState.loading
                : authState.loading;

            if (user) {
                setTelemetryUser({
                    email: user.email,
                    id: user.uid,
                    name: user.displayName,
                });
            }

            unsub();
            resolve(user);
        }, reject);
    });
};

const isUserSignedIn = async () => {
    try {
        return (await getCurrentUser()) != null;
    } catch (error) {
        reportError(error);
        return false;
    }
};

const signIn = async (userCode) => {
    const signInFunction = httpsCallable(functions, "getMobileLoginToken");

    const response = await signInFunction({
        dtCode: userCode,
    });

    if (response.data == null) {
        return false;
    }

    await signInWithCustomToken(auth, response.data);
};

const userSignOut = async (skipNagivation) => {
    try {
        await signOut(auth);

        authState.authenticated = AUTH_UNKNOWN;
        setTelemetryUser(null);

        if (!skipNagivation) {
            window.location.replace(`/${SIGN_IN_PATH}`);
        }
    } catch (error) {
        reportError(error);
    }
};

export const useAuth = () => {
    return {
        authState,
        getCurrentUser,
        signIn,
        isUserSignedIn,
        userSignOut,
    };
};
