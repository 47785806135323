<script setup>
const props = defineProps({
    iconFilename: { type: String, required: true },
});

const getImageUrl = () => {
    return new URL(`../../assets/${props.iconFilename}`, import.meta.url);
};
</script>

<template>
    <div class="p-4">
        <div class="flex flex-col sm:flex-row">
            <img
                :src="getImageUrl()"
                alt=""
                class="w-32 h-32 mr-4 self-center"
            />
            <div class="self-center">
                <slot></slot>
            </div>
        </div>
    </div>
</template>
