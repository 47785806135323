<script setup>
import {
    toastState,
    isToastClose,
    closeToast,
    toastStatus,
} from "../../services/utils/toast";
import Cross from "../icons/CrossIcon.vue";

const getToastColors = () => {
    switch (toastState.status) {
        case toastStatus.INFO:
            return "border-bluesteel bg-bluesteel-tint-5";
        case toastStatus.WARNING:
            return "border-sunrise bg-sunrise-tint";
        case toastStatus.SUCCESS:
            return "border-peppermint bg-peppermint-tint";
        case toastStatus.ERROR:
            return "border-red-tint bg-red-tint-6";
    }
};
</script>

<template>
    <div
        :class="`toast-animate p-3 fixed border ${getToastColors()} dark-text rounded-sm bottom-4 left-1/3 shadow-md w-1/3 z-[1000] ${
            isToastClose() ? 'hidden z-[-1]' : 'show'
        }`"
    >
        <span class="text-center">{{ toastState.text }}</span>
        <i
            class="absolute px-2 right-0 hover:bg-peppermint-tint"
            @click="closeToast"
            ><Cross></Cross
        ></i>
    </div>
</template>

<style>
.toast-animate.show {
    visibility: visible;
    -webkit-animation: fadein 0.5s;
    animation: fadein 0.5s;
}
@keyframes fadein {
    from {
        bottom: 0;
        opacity: 0;
    }
    to {
        bottom: 30px;
        opacity: 1;
    }
}
</style>
