<script setup>
const props = defineProps({
    size: { type: Number, required: false, default: 4 },
});
</script>
<template>
    <svg
        viewBox="0 0 1024 1024"
        :class="`fill-current stroke-current w-${props.size} h-${props.size} inline-block`"
        xmlns="http://www.w3.org/2000/svg"
    >
        <slot></slot>
    </svg>
</template>
