import WalkerBeforeDelivery from "../components/sections/walkers/WalkerBeforeDelivery.vue";
import WalkerConfirm from "../components/sections/walkers/WalkerConfirm.vue";
import WalkerDoNotDo from "../components/sections/walkers/WalkerDoNotDo.vue";
import WalkerToDo from "../components/sections/walkers/WalkerToDo.vue";
import WalkerWelcome from "../components/sections/walkers/WalkerWelcome.vue";
import WalkerMakingDeliveries from "../components/sections/walkers/WalkerMakingDeliveries.vue";
import {
    BASE_PATH,
    CONFIRMED_ACK_PATH,
    NOT_FOUND_PATH,
    SIGN_IN_PATH,
    WALKER_BEFORE_DELIVERY_PAGE,
    WALKER_CONFIRM_PAGE,
    WALKER_DO_NOT_DO_PAGE,
    WALKER_TO_DO_PAGE,
    WALKER_WELCOME_PAGE,
    WALKER_MAKING_DELIVERIES_PAGE,
} from "../const/route";
import Home from "../views/HomeView.vue";
import NotFound from "../views/NotFoundView.vue";
import ResponseSuccess from "../views/ResponseSuccessView.vue";
import SignIn from "../views/SignInView.vue";

export const routes = [
    {
        path: BASE_PATH,
        name: BASE_PATH,
        component: Home,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: `${BASE_PATH}${WALKER_WELCOME_PAGE}`,
        name: WALKER_WELCOME_PAGE,
        component: WalkerWelcome,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: `${BASE_PATH}${WALKER_BEFORE_DELIVERY_PAGE}`,
        name: WALKER_BEFORE_DELIVERY_PAGE,
        component: WalkerBeforeDelivery,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: `${BASE_PATH}${WALKER_MAKING_DELIVERIES_PAGE}`,
        name: WALKER_MAKING_DELIVERIES_PAGE,
        component: WalkerMakingDeliveries,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: `${BASE_PATH}${WALKER_TO_DO_PAGE}`,
        name: WALKER_TO_DO_PAGE,
        component: WalkerToDo,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: `${BASE_PATH}${WALKER_DO_NOT_DO_PAGE}`,
        name: WALKER_DO_NOT_DO_PAGE,
        component: WalkerDoNotDo,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: `${BASE_PATH}${WALKER_CONFIRM_PAGE}`,
        name: WALKER_CONFIRM_PAGE,
        component: WalkerConfirm,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: `${BASE_PATH}${CONFIRMED_ACK_PATH}`,
        name: CONFIRMED_ACK_PATH,
        component: ResponseSuccess,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: `${BASE_PATH}:pathMatch(.*)`,
        name: NOT_FOUND_PATH,
        component: NotFound,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: `${BASE_PATH}${SIGN_IN_PATH}`,
        name: SIGN_IN_PATH,
        component: SignIn,
    },
];
