<script setup>
import { useRouter } from "vue-router";
import BaseView from "../../views/BaseView.vue";
import ConfirmButton from "../buttons/ConfirmButton.vue";
import UtilityButton from "../buttons/UtilityButton.vue";
import ChevronLeft from "../icons/ChevronLeft.vue";

const props = defineProps({
    title: { type: String, required: true },
    nextButtonLabel: { type: String, default: "Next" },
    nextPath: { type: String, required: true },
    isSubmit: { type: Boolean, default: false },
    showBackButton: { type: Boolean, default: true },
});

const router = useRouter();

const goBack = () => {
    router.back();
};

const onNext = (ev) => {
    ev.preventDefault();
    router.push({ name: props.nextPath });
};
</script>

<template>
    <BaseView>
        <div class="self-center mb-8 mt-16 px-8 w-full sm:w-3/4 lg:w-1/2">
            <div class="flex justify-between mb-2">
                <ChevronLeft
                    v-if="props.showBackButton"
                    :size="8"
                    @click="goBack"
                    class="text-peppermint hover:cursor-pointer"
                />
                <div v-else></div>
                <h3 class="font-bold text-xl">{{ props.title }}</h3>
                <div></div>
            </div>
            <slot></slot>
            <ConfirmButton v-if="props.isSubmit" />
            <div v-else class="text-center">
                <UtilityButton
                    :label="props.nextButtonLabel"
                    :on-click="onNext"
                />
            </div>
        </div>
    </BaseView>
</template>
