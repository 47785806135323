<script setup>
import Icon from "./IconBase.vue";

const props = defineProps({
    size: { type: Number, required: false, default: 4 },
});
</script>
<template>
    <Icon :size="props.size">
        <path
            class="path1"
            d="M691.2 588.8c-6.552 0-13.102-2.499-18.101-7.499-9.998-9.997-9.998-26.206 0-36.203l157.083-157.083c25.936-25.936 40.221-60.421 40.221-97.099s-14.285-71.162-40.221-97.098c-53.539-53.538-140.654-53.541-194.197 0l-157.083 157.082c-9.997 9.998-26.206 9.998-36.203 0-9.998-9.997-9.998-26.206 0-36.203l157.083-157.083c73.504-73.502 193.104-73.499 266.603 0 35.608 35.606 55.218 82.947 55.218 133.302s-19.61 97.696-55.218 133.302l-157.083 157.082c-5 5.002-11.55 7.501-18.102 7.501z"
        ></path
        ><path
            class="path2"
            d="M239.717 972.802c-50.355 0-97.696-19.61-133.302-55.218-73.501-73.501-73.501-193.101 0-266.603l157.083-157.083c9.997-9.998 26.206-9.998 36.203 0 9.998 9.997 9.998 26.206 0 36.203l-157.082 157.083c-53.538 53.541-53.538 140.658 0 194.197 25.936 25.936 60.419 40.221 97.098 40.221s71.162-14.285 97.098-40.221l157.083-157.083c9.997-9.997 26.206-9.997 36.203 0 9.998 9.997 9.998 26.206 0 36.203l-157.083 157.083c-35.605 35.608-82.946 55.218-133.301 55.218z"
        ></path
        ><path
            class="path3"
            d="M281.6 358.4c-6.552 0-13.102-2.499-18.101-7.499l-102.4-102.4c-9.998-9.997-9.998-26.206 0-36.203 9.997-9.998 26.206-9.998 36.203 0l102.4 102.4c9.998 9.997 9.998 26.206 0 36.203-5 5-11.55 7.499-18.102 7.499z"
        ></path
        ><path
            class="path4"
            d="M384 307.2c-14.138 0-25.6-11.462-25.6-25.6v-153.6c0-14.138 11.462-25.6 25.6-25.6s25.6 11.462 25.6 25.6v153.6c0 14.138-11.462 25.6-25.6 25.6z"
        ></path
        ><path
            class="path5"
            d="M230.4 460.8h-153.6c-14.138 0-25.6-11.462-25.6-25.6s11.462-25.6 25.6-25.6h153.6c14.138 0 25.6 11.462 25.6 25.6s-11.462 25.6-25.6 25.6z"
        ></path
        ><path
            class="path6"
            d="M793.6 870.4c-6.552 0-13.102-2.499-18.101-7.499l-102.4-102.4c-9.998-9.997-9.998-26.206 0-36.203 9.997-9.997 26.206-9.997 36.203 0l102.4 102.4c9.998 9.997 9.998 26.206 0 36.203-5 5-11.55 7.499-18.102 7.499z"
        ></path
        ><path
            class="path7"
            d="M896.002 665.6h-153.602c-14.139 0-25.6-11.461-25.6-25.6s11.461-25.6 25.6-25.6h153.602c14.139 0 25.6 11.461 25.6 25.6s-11.461 25.6-25.6 25.6z"
        ></path
        ><path
            class="path8"
            d="M588.8 972.8c-14.139 0-25.6-11.461-25.6-25.6v-153.6c0-14.139 11.461-25.6 25.6-25.6s25.6 11.461 25.6 25.6v153.6c0 14.139-11.461 25.6-25.6 25.6z"
        ></path>
    </Icon>
</template>
