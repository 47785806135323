export const BASE_PATH = "/";
export const NOT_FOUND_PATH = "not-found";
export const SIGN_IN_PATH = "signin";
export const CONFIRMED_ACK_PATH = "thankyou";

// Walkers
export const WALKER_WELCOME_PAGE = "welcome-walkers";
export const WALKER_BEFORE_DELIVERY_PAGE = "before-delivery";
export const WALKER_MAKING_DELIVERIES_PAGE = "making-deliveries";
export const WALKER_TO_DO_PAGE = "delivery-to-do";
export const WALKER_DO_NOT_DO_PAGE = "delivery-dont";
export const WALKER_CONFIRM_PAGE = "confirm";
