<script setup>
import { useAuth } from "../../services/firebase";
import { useDropdown } from "../../services/utils/dropdown";
import { BASE_PATH } from "../../const/route";

const { authState, userSignOut } = useAuth();
const user = authState.auth.currentUser;

const [clickEventHandler, isHidden] = useDropdown();

const signOut = () => userSignOut(false);
</script>

<template>
    <header>
        <nav class="fixed h-11 top-0 w-full bg-dark-grey z-10">
            <div
                class="w-full mx-auto flex items-center h-full justify-between"
            >
                <router-link :to="BASE_PATH">
                    <img
                        class="h-5 md:h-7 ml-4 my-2"
                        src="../../assets/reach-white-on-dark.png"
                        alt=""
                    />
                </router-link>

                <div class="h-full relative">
                    <ul class="h-full">
                        <li
                            data-key="profile-dropdown"
                            class="items-center text-white uppercase flex hover:bg-black hover:cursor-pointer h-full px-8"
                            @click="
                                clickEventHandler($event, 'profile-dropdown')
                            "
                        >
                            <span data-key="profile-dropdown">{{
                                user?.uid ?? ""
                            }}</span>
                        </li>
                        <li
                            class="absolute px-4 py-3 bg-black text-white hover:bg-black hover:cursor-pointer w-full text-sm md:text-base md:w-full right-0"
                            :class="{ hidden: isHidden() }"
                            @click="signOut"
                        >
                            Sign Out
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </header>
</template>
